.layout {
    display: flex;
  }
  
  .sidebar {
    position: sticky;
    top: 0;
    height: 50vh;
    width: 200px;
    padding: 20px;
  }
  
  .sidebar nav ul {
    list-style: none;
    padding: 0;
  }
  
  .sideBarli {
    margin: 10px 0;
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    padding: 20px;  
  }
  
  img.desktopImg {
    width: 100%;
    height: auto;
  }
  
  button.desktopButton {
    padding: 10px;
    border: none;
    background: #000000; 
    color: #fff;
    cursor: pointer;
  }

  ul.sideBar {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #ffffff;
    text-decoration: none;
  }
  
  
  li.sideBar {
    display: block;
    color: #ffffff;
    text-align: left;
    text-decoration: none;
  }
  a.navBarLeft {
    display: block;
    color: #000000;
    padding: 14px 16px;
    text-decoration: none;

  }
  a.sideBar {
    display: block;
    color: #000000;
    text-decoration: none;
  }
  a.sideBar:hover{
    opacity: 0.5;
  }

  li.sideBar:hover{
    opacity: 0.5;
  }

  h2.sideBar{
    text-align: center;
  }
  
  .desktopModal-overlay {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .desktopModal-content {
    color: #fff;
    padding: 10px;
    border-radius: 4px;
  }
  
  .desktopModal-image {
    max-height: 800px;
    max-width: 800px;
    object-fit: cover;
    margin-right: 20px;
  }
  
  .desktopModal-text {
    font-size: 16px;
    color: black;
  }

  .desktopModal-close {
    position: absolute;
    font-size: 24px;
    background-color: white;
    color: #000000;
    border: none;
    cursor: pointer;
  }
  .desktopModal-close:hover{
    background-color: black;
    color: white;
  }
  .sideBar .dropdown {
    position: relative;
  }
  
  .sideBar .arrow {
    font-size: 0.8em;
    margin-left: 5px;
  }
  .dropdown-item {
    margin-left: 20px;
    margin: 10px 0;
  }

.dropdown {
    cursor: pointer;
  }
  
  .dropbtn {
    background: none;
    border: none;
    font-size: inherit;
    font-family: inherit;
    color: inherit;
    cursor: pointer;
    padding: 0;
    text-align: left;
    width: 100%;
    color: #000000;
  }
  .dropdown-content {
    position: static;
    display: block;
    background-color: transparent;
    box-shadow: none;
  }


  @media (max-width: 1100px) {
    .desktopModal-image {
        max-height: 700px;
        max-width: 700px;
        object-fit: cover;
        margin-right: 20px;
      }
      
  }

  @media (max-width: 900px) {
    .desktopModal-image {
        max-height: 600px;
        max-width: 600px;
        object-fit: cover;
        margin-right: 20px;
      }
      .desktopModal-overlay {
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        display: flex;
        justify-content: left;
        align-items: center;
      }
      
  }
  @media (max-width: 750px) {
    .desktopModal-image {
        max-height: 500px;
        max-width: 500px;
        object-fit: cover;
        margin-right: 20px;
      }
      .desktopModal-overlay {
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        display: flex;
        justify-content: left;
        align-items: center;
      }
      
  }
